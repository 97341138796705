import { Divider, Stack } from "@mui/material";
import FaqItems, { FaqItem } from "./FaqItems";
import Section from "../Section";
import { useCallback, useEffect, useState } from "react";
import Seo from "./Seo";
import Related from "./Related";
import { LoadingButton } from "@mui/lab";

type Props = {
  meta: string;
  onSubmit?: (val: MetaType) => void;
  btnIsLoading?: boolean;
};

export type MetaType = {
  faqItems?: FaqItem[];
  seo?: {
    heading: string;
    description: string;
  };
  relatedSearch?: string;
};

export default function LandingMetaHandler({
  meta,
  onSubmit,
  btnIsLoading = false,
}: Props) {
  const [values, setValues] = useState<MetaType | null>(null);
  useEffect(() => {
    let metaParsed: MetaType | null = null;
    try {
      metaParsed = JSON.parse(meta);
      setValues(metaParsed);
    } catch (e) {}
  }, []);

  const handleChangeValues = useCallback(
    (key: string, val: any) => setValues((crt) => ({ ...crt, [key]: val })),
    []
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={"flex-start"}
        width={"100%"}
        sx={{ my: 6 }}
      >
        <Section title='Faq Items' sx={{ width: "100%" }}>
          <FaqItems
            defaultItems={values?.faqItems || []}
            onChange={(values: any) => handleChangeValues("faqItems", values)}
          />
        </Section>
        <Divider />
        <Section title='SEO' sx={{ width: "100%" }}>
          <Seo
            defaultItem={values?.seo || { heading: "", description: "" }}
            onChange={(values: any) => {
              handleChangeValues("seo", values);
            }}
          />
        </Section>
        <Divider />
        <Section title='SEARCH' sx={{ width: "100%" }}>
          <Related
            defaultItem={{ search: values?.relatedSearch || "" }}
            onChange={(values: any) => {
              handleChangeValues("relatedSearch", values.search);
            }}
          />
        </Section>
      </Stack>
      <LoadingButton
        loading={btnIsLoading}
        variant='contained'
        onClick={() => onSubmit(values)}
        sx={{ width: 200, mt: 6 }}
      >
        Save
      </LoadingButton>
    </>
  );
}
