import { LazyComponent, PublicRoute } from ".";
import { Navigate, createBrowserRouter } from "react-router-dom";

import { OutletWrapper } from "@/components/shared";
import PrivateRoute from "./PrivateRoute";
import { Routes } from "@/routes";
import { lazy } from "react";

//dashboard
const DashboardWrapper = lazy(() => import("@/components/DashboardWrapper"));
const DashboardHome = lazy(() => import("@/components/pages/DashboardHome"));
const NotFound = lazy(() => import("@/components/pages/404"));
//auth
const AuthWrapper = lazy(() => import("@/components/AuthWrapper"));
const SignOut = lazy(() => import("@/components/pages/auth/Logout"));
const SignIn = lazy(() => import("@/components/pages/auth/Login"));
const Profile = lazy(() => import("@/components/pages/profile"));

//payment
const PaymentMethods = lazy(() => import("@/components/pages/PaymentMethods"));
const AddPaymentMethod = lazy(
  () => import("@/components/pages/PaymentMethods/AddPaymentMethod")
);
//orders
const Orders = lazy(() => import("@/components/pages/orders"));
const ManageOrder = lazy(() => import("@/components/pages/orders/ManageOrder"));
//currencies
const Currencies = lazy(() => import("@/components/pages/currencies"));
const ManageCurrency = lazy(
  () => import("@/components/pages/currencies/ManageCurrency")
);
//crypto_networks
const CryptoNetworks = lazy(() => import("@/components/pages/crypto-networks"));
const ManageCryptoNetwork = lazy(
  () => import("@/components/pages/crypto-networks/ManageCrypto")
);
//landings
const Landings = lazy(() => import("@/components/pages/landings"));
const ManageLanding = lazy(
  () => import("@/components/pages/landings/ManageLanding")
);
//users
const Users = lazy(() => import("@/components/pages/users"));
const Awaitings = lazy(() => import("@/components/pages/users/awaitings"));
const ManageUser = lazy(() => import("@/components/pages/users/view"));
//settings
const Settings = lazy(() => import("@/components/pages/settings"));
//tickets
const Tickets = lazy(() => import("@/components/pages/tickets"));
const ViewTicket = lazy(() => import("@/components/pages/tickets/view"));
const AddTicket = lazy(() => import("@/components/pages/tickets/add"));
//transactions
const TransactionsPage = lazy(
  () => import("@/components/pages/transaction-list")
);
const TransactionsView = lazy(
  () => import("@/components/pages/transaction-list/ManageTransaction")
);
//comments
const CommentsPage = lazy(() => import("@/components/pages/comments"));
const CommentView = lazy(
  () => import("@/components/pages/comments/ManageComment")
);

const browserRouter = () => {
  const router = createBrowserRouter([
    {
      path: Routes.home,
      element: (
        <Navigate to={`${Routes.dashboard.index}${Routes.dashboard.home}`} />
      ),
    },
    {
      path: Routes.dashboard.index,
      element: (
        <LazyComponent
          element={<PrivateRoute element={<DashboardWrapper />} />}
        />
      ),
      children: [
        {
          path: Routes.dashboard.home,
          element: <LazyComponent element={<DashboardHome />} />,
        },
        {
          path: Routes.dashboard.paymentMethods.index,
          element: <LazyComponent element={<OutletWrapper />} />,
          children: [
            {
              path: "",
              element: <LazyComponent element={<PaymentMethods />} />,
            },
            {
              path: Routes.dashboard.paymentMethods.add,
              element: <LazyComponent element={<AddPaymentMethod />} />,
            },
            {
              path: Routes.dashboard.paymentMethods.view,
              element: <LazyComponent element={<AddPaymentMethod />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.orders.index,
          element: <LazyComponent element={<OutletWrapper />} />,
          children: [
            {
              path: "",
              element: <LazyComponent element={<Orders />} />,
            },
            {
              path: Routes.dashboard.orders.view,
              element: <LazyComponent element={<ManageOrder />} />,
            },
            {
              path: Routes.dashboard.orders.add,
              element: <LazyComponent element={<ManageOrder />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.currencies.index,
          element: <LazyComponent element={<OutletWrapper />} />,
          children: [
            {
              path: "",
              element: <LazyComponent element={<Currencies />} />,
            },
            {
              path: Routes.dashboard.currencies.view,
              element: <LazyComponent element={<ManageCurrency />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.crypto_networks.index,
          element: <LazyComponent element={<OutletWrapper />} />,
          children: [
            {
              path: "",
              element: <LazyComponent element={<CryptoNetworks />} />,
            },
            {
              path: Routes.dashboard.crypto_networks.view,
              element: (
                <LazyComponent element={<ManageCryptoNetwork isSingle />} />
              ),
            },
            {
              path: Routes.dashboard.crypto_networks.add,
              element: <LazyComponent element={<ManageCryptoNetwork />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.landings.index,
          element: <LazyComponent element={<OutletWrapper />} />,
          children: [
            {
              path: "",
              element: <LazyComponent element={<Landings />} />,
            },
            {
              path: Routes.dashboard.landings.view,
              element: <LazyComponent element={<ManageLanding />} />,
            },
            {
              path: Routes.dashboard.landings.add,
              element: <LazyComponent element={<ManageLanding />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.users.index,
          element: <LazyComponent element={<OutletWrapper />} />,
          children: [
            {
              path: "",
              element: <LazyComponent element={<Users />} />,
            },
            {
              path: Routes.dashboard.users.add,
              element: <LazyComponent element={<ManageUser />} />,
            },
            {
              path: Routes.dashboard.users.awaitings,
              element: <LazyComponent element={<Awaitings />} />,
            },
            {
              path: Routes.dashboard.users.view,
              element: <LazyComponent element={<ManageUser />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.tickets.index,
          element: <LazyComponent element={<OutletWrapper />} />,
          children: [
            {
              path: "",
              element: <LazyComponent element={<Tickets />} />,
            },
            {
              path: Routes.dashboard.tickets.view,
              element: <LazyComponent element={<ViewTicket />} />,
            },
            {
              path: Routes.dashboard.tickets.add,
              element: <LazyComponent element={<AddTicket />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.tickets.index,
          element: <LazyComponent element={<OutletWrapper />} />,
          children: [
            {
              path: "",
              element: <LazyComponent element={<TransactionsPage />} />,
            },
            {
              path: Routes.dashboard.tickets.view,
              element: <LazyComponent element={<TransactionsView />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.transactions.index,
          element: <LazyComponent element={<OutletWrapper />} />,
          children: [
            {
              path: "",
              element: <LazyComponent element={<TransactionsPage />} />,
            },
            {
              path: Routes.dashboard.tickets.view,
              element: <LazyComponent element={<TransactionsView />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.comments.index,
          element: <LazyComponent element={<OutletWrapper />} />,
          children: [
            {
              path: "",
              element: <LazyComponent element={<CommentsPage />} />,
            },
            {
              path: Routes.dashboard.comments.view,
              element: <LazyComponent element={<CommentView />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.settings,
          element: <LazyComponent element={<Settings />} />,
        },
        {
          path: Routes.dashboard.profile,
          element: <LazyComponent element={<Profile />} />,
        },
      ],
    },
    {
      path: Routes.auth.index,
      element: <LazyComponent element={<AuthWrapper />} />,
      children: [
        {
          path: Routes.auth.login,
          element: (
            <LazyComponent element={<PublicRoute element={<SignIn />} />} />
          ),
        },
        {
          path: Routes.auth.logout,
          element: (
            <LazyComponent element={<PrivateRoute element={<SignOut />} />} />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <LazyComponent element={<NotFound />} />,
    },
  ]);

  return router;
};

export default browserRouter;
