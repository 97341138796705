const routes = {
  home: "/",
  dashboard: {
    index: "/dashboard/",
    home: "home",
    paymentMethods: {
      index: "payment-methods",
      add: "add",
      view: ":id",
    },
    tickets: {
      index: "tickets",
      view: ":id",
      add: "add",
    },
    orders: {
      index: "orders",
      view: ":id",
      add: "add-order",
    },
    currencies: {
      index: "currencies",
      view: ":id",
    },
    crypto_networks: {
      index: "crypto-networks",
      view: ":id",
      add: "add-network",
    },
    landings: {
      index: "landings",
      view: ":id",
      add: "add-landing",
    },
    users: {
      index: "users",
      awaitings: "awaitings",
      add: "add",
      view: ":id",
    },
    transactions: {
      index: "transactions",
      view: ":id",
    },
    comments: {
      index: "comments",
      view: ":id",
    },
    settings: "settings",
    profile: "profile",
  },
  auth: {
    index: "/auth",
    login: "login",
    logout: "logout",
    forget_password: "forget_password",
    reset_password: "reset_password",
  },
};

export default routes;
